$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1200px;
$screen-xl: 1600px;

html {
  font-size: 12px;
  font-family: "Poppins", Courier, monospace;

  @media screen and (min-width: $screen-sm) {
    font-size: 16px;
  }
}

.heading-1 {
  font-size: 3rem;
}

.heading-2 {
  font-size: 2.5rem;
}

.heading-3 {
  font-size: 2rem;
}

.heading-4 {
  font-size: 1.5rem;
}

.heading-5 {
  font-size: 1rem;

  @media screen and (min-width: $screen-sm) {
    font-size: 1.5rem;
  }
}

.sub-heading {
  font-size: 1rem;
}

.paragraph {
  font-size: 1rem;
}

.small-text {
  font-size: 0.8rem;
}