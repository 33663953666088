// Colors
@import "./constants/colors.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
$screen-sm: "768px";

body {
  background: linear-gradient(55deg, #c2f2ec 0%, #ecf0f3 51%, #c2e3f5 85%);
  color: #4f4f4f;
  font-family: "Poppins", Courier, monospace;
}

.App {
  overflow: hidden;

  .blur {
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }

  .section {
    min-height: 100vh;
    position: relative;
    // overflow-x: hidden;
    text-align: left;
  }

  .section-title-wrapper {
    margin-top: 100px;

    .section-title {
      color: $light-gray;
      text-transform: uppercase;
      margin-right: 30px;
      font-size: 1.6rem;
    }

    .line {
      height: 1px;
      width: 100%;
      background-color: $light-blue;
    }
  }

  .SkillBtn {
    width: 76px;
    height: 76px;
    background: #e8ebf0;
    padding: 0px;
    border-radius: 20px;
    box-shadow: -10px -10px 15px rgba(255, 255, 255, 0.5),
      10px 10px 15px rgba(70, 70, 70, 0.12);
    font-size: 8px;
    text-align: center;
    font-weight: bold;

    @media screen and (min-width: $screen-sm) {
      width: 100px;
      height: 100px;
      padding: 20px;
      font-size: 10px;
    }

    img {
      margin-bottom: 10px;
      height: 20px;

      @media screen and (min-width: $screen-sm) {
        height: 30px;
      }
    }
  }

  .Header {
    display: flex;
    justify-content: space-between;
    height: 80px;
    align-items: center;
    background: rgba(255, 255, 255, 0.25);
    background-color: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    position: fixed;
    width: 100%;
    z-index: 2;

    &__menu-list {
      a {
        margin-right: 30px;
        text-decoration: none;
        color: #4f4f4f;
        text-transform: uppercase;
      }
    }

    .header-menu {
      &--mobile {
        display: block;

        @media screen and (min-width: $screen-sm) {
          display: none;
        }
      }

      &--web {
        display: none;

        @media screen and (min-width: $screen-sm) {
          display: block;
        }
      }
    }
  }

  .SocialIcons {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 30px;

    a {

      img {
        height: 20px;
        opacity: 0.5;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

    }

    img:last-child {
      padding-right: 0px;
    }
  }

  .Hero {

    svg .svg-elem-1 {
      stroke-dashoffset: 2770.3916015625px;
      stroke-dasharray: 2770.3916015625px;
      -webkit-transition: stroke-dashoffset 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
      transition: stroke-dashoffset 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s;
    }

    svg.active .svg-elem-1 {
      stroke-dashoffset: 5540.783203125px;
    }


    .hero-row {
      &__text {
        order: 2;
        margin-top: 40px;

        @media screen and (min-width: $screen-sm) {
          order: 1;
        }

        .name {
          color: transparent;
          -webkit-text-stroke: 1px #242132;
          font-size: 4rem;

          @media screen and (min-width: $screen-sm) {
            margin: 20px 0px;
          }
        }

        .paragraph {
          .dot {
            margin-left: 5px;
            margin-right: 5px;
          }
        }

        .heading-4 {
          @media screen and (min-width: $screen-sm) {
            width: 75%;
          }
        }
      }

      &__img {
        order: 1;

        @media screen and (min-width: $screen-sm) {
          order: 2;
        }

        img {
          max-height: 400px;
          min-width: 200px;
          width: 60%;
        }
      }
    }
  }

  .Works {
    .section-title-wrapper {
      margin-bottom: 20px;
    }

    .work-cards {

      .owl-carousel {
        .owl-nav {
          .nav-button {
            cursor: pointer;
            position: absolute;
            top: 35%;
            color: #4F4F4F;
            background: none;
            padding: 0px;
            margin: 0px;
            font-size: 150px;
            font-weight: bold;
            transition: 0.2s ease-in;
            opacity: 0.25;

            @media screen and (min-width: $screen-sm) {
              font-size: 200px;
              top: 25%;
            }

            &:hover {
              scale: 1.2;
              color: #4F4F4F;
              background: none;
            }

            &.owl-prev {
              left: 0px;
            }

            &.owl-next {
              right: 0px;
            }
          }
        }
      }
    }

    .work-carousel {
      @media screen and (min-width: $screen-sm) {
        padding-left: 100px;

      }
    }
  }

  .Skills {
    .section-title-wrapper {
      margin-bottom: 40px;
    }

    .skill-section {
      .skill-type-wrapper {
        .type {
          margin-bottom: 10px;
          color: #8da0a4;
          font-size: 1rem;
        }

        .SkillBtn {
          margin-right: 30px;
          margin-bottom: 30px;
          color: #4F4F4F;
          text-transform: uppercase;
        }
      }
    }
  }

  .About {
    .about-section {
      flex: 1
    }

    .description {
      color: #4f4f4f;
      opacity: 0.8;
      font-size: 1.6rem;
      width: 100%;

      @media screen and (min-width: $screen-sm) {
        font-size: 1.2rem;
        width: 50%;
      }
    }

    .contact-text {
      font-size: 3rem;
      color: transparent;
      -webkit-text-stroke: 1px #242132;
    }

    .email {
      color: #313435;
      opacity: 0.5;
      text-decoration: none;
    }




    .Footer {
      button {
        all: unset;
        cursor: pointer;
        color: $light-gray;
        text-transform: lowercase;
        font-size: 0.8rem;
        color: #4f4f4f;
        opacity: 0.5;
        width: 50%;


      }

      &__highlight {
        text-decoration: underline;

        &:hover {
          color: $blue;
        }
      }
    }
  }

  .Card {
    // width: 600px;
    font-size: 14px;

    .inner-section {
      padding: 60px 30px 30px 30px;
      background-color: rgba(255, 255, 255, 0.5);
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(4px);
      border-radius: 5px;
      margin: 0px 40px;

      @media screen and (min-width: $screen-sm) {
        min-height: 300px;
        margin: 0px;
      }

      .name {
        font-size: 1.6rem;
        margin-bottom: 5px;

        @media screen and (min-width: $screen-sm) {
          font-size: 1.2rem;
        }
      }

      .company {
        font-size: 1rem;
        margin-bottom: 15px;

        @media screen and (min-width: $screen-sm) {
          font-size: 0.8rem;
        }
      }

      .description {
        margin-bottom: 15px;

        @media screen and (min-width: $screen-sm) {
          font-size: 0.8rem;
        }
      }

      .tech {
        font-size: 1rem;
        font-weight: bold;
        color: #707a7b;

        @media screen and (min-width: $screen-sm) {
          font-size: 0.6rem;
        }
      }
    }

    .outer-section {
      border-radius: 30px;

      .year {
        padding-left: 40px;
        width: 20%;
        font-size: 2rem;
        font-weight: bold;
        color: #9fbbb9;

        @media screen and (min-width: $screen-sm) {
          padding-left: 00px;
        }
      }

      .image-container {
        position: relative;
        bottom: -50px;
        width: 80%;
        display: flex;
        justify-content: center;
        z-index: 1;
        scale: 1.4;
        right: 13px;

        &--mobile {
          bottom: -60px;
          scale: 1.5;
          right: -20px;
        }

        img {
          width: 90%;
        }
      }
    }
  }

  .ScrollText {
    a {
      text-decoration: none;
      font-size: 3rem;
      color: transparent;
      -webkit-text-stroke: 1px #242132;
    }

    .marquee {
      display: flex;
      margin-bottom: 30px;
      align-items: center;
      cursor: -webkit-grab;
    }

    .item {
      font-size: 8vw;
      letter-spacing: -0.05em;
      padding-right: 0.25em;
      white-space: nowrap;
      -webkit-user-select: none;
      user-select: none;
      /* will-change: transform; */
    }

    .drag {
      cursor: -webkit-grabbing;
      cursor: grabbing;
    }
  }
}